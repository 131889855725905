export const Posts = {
    NEW_POSTS_ARRAY: "NEW_POSTS_ARRAY",
    POSTS_DELETE: "POSTS_DELETE",
    POSTS_ARRAY: "POSTS_ARRAY",
    POSTS_ARRAY_SAGA: "POSTS_ARRAY_SAGA",
    POSTS_COMMENTS: "POSTS_COMMENTS",
    POSTS_COMMENT_SAGA: "POSTS_COMMENT_SAGA",
    POSTS_LOADING: "POSTS_LOADING",
    POSTS_COMMENT_LOADING: "POSTS_COMMENT_LOADING",
    LIKE_OR_DISLIKE: "LIKE_OR_DISLIKE",
    LIKE_OR_DISLIKE_SAGA: "LIKE_OR_DISLIKE_SAGA",
    ADD_COMMENT: "ADD_COMMENT",
    ADD_COMMENT_SAGA: "ADD_COMMENT_SAGA",
    NEW_COMMENT_VALUE: "NEW_COMMENT_VALUE",
    ADD_POSTS_COMMENTS: "ADD_POSTS_COMMENTS",
    ADD_COMMENT_LOADING: "ADD_COMMENT_LOADING",
    POST_DATA: "POST_DATA",
    POST_DATA_SAGA: "POST_DATA_SAGA",
    POST_DATA_LOADING: "POST_DATA_LOADING",
    POST_PAGE_COMMENTS_LOADING: "POST_PAGE_COMMENTS_LOADING",
    POST_PAGE_COMMENTS: "POST_PAGE_COMMENTS",
    POST_PAGE_COMMENT_INPUT_VALUE: "POST_PAGE_COMMENT_INPUT_VALUE",
    POST_PAGE_CHILD_COMMENT_INPUT_VALUE: "POST_PAGE_CHILD_COMMENT_INPUT_VALUE",
    ADD_POST_PAGE_COMMENT: "ADD_POST_PAGE_COMMENT",
    HIDE_POST_SAGA: "HIDE_POST_SAGA",
    POST_REPORT_SAGA: "POST_REPORT_SAGA",
    POPULAR_MASTER_SAGA: "POPULAR_MASTER_SAGA",
    POPULAR_MASTER: "POPULAR_MASTER",
    POSTS_ARRAY_HASH_TAG: "POSTS_ARRAY_HASH_TAG",
    DELETE_POST_DATA: "DELETE_POST_DATA",
    ADD_VIEW: "ADD_VIEW",
    GET_POSTS_SLIDER:"GET_POSTS_SLIDER",
    SET_POSTS_SLIDER:"SET_POSTS_SLIDER",
    GET_HELP:"GET_HELP",
    SET_HELP:"SET_HELP"
};
export const LoginRegis = {
    LOGIN: "LOGIN",
    DELETE_CODE: "DELETE_CODE",
    CLOSE_ALERT: "CLOSE_ALERT",
    LOGIN_LOADING: "LOGIN_LOADING",
    LOGIN_SAGA: "LOGIN_SAGA",
    LOGIN_SUBMITTED: "LOGIN_SUBMITTED",
    LOGIN_UMOUNT: "LOGIN_UMOUNT",
    LOGIN_WITH_PHONE_NUMBER_SAGA:"LOGIN_WITH_PHONE_NUMBER_SAGA",
    CODE_FOR_LOGIN_SAGA:"CODE_FOR_LOGIN_SAGA",
    REGIS: "REGIS",           //REGISTRATION
    REGIS_LOADING: "REGIS_LOADING",
    REGIS_SUBMITTED: "REGIS_SUBMITTED",
    REGIS_SAGA: "REGIS_SAGA",
    CLOSE_REGIS_ALERT: "CLOSE_REGIS_ALERT",
    REGIS_CODE_SAGA: "REGIS_CODE_SAGA",
    REGIS_CODE: "REGIS_CODE",
    REGIS_CODE_SUBMITTED: "REGIS_CODE_SUBMITTED",
    REGIS_CODE_LOADING: "REGIS_CODE_LOADING",
    SET_REGIS_INPUT_VALUE: "SET_REGIS_INPUT_VALUE",
    SHOW_CODE: "SHOW_CODE",
    GET_FORGET_INPUT_VALUES: "GET_FORGET_INPUT_VALUES", //FORGET PASSWORD
    FORGET_PASSWORD_LOADING: "FORGET_PASSWORD_LOADING",
    PHONE_OR_EMAIL_RES: "PHONE_OR_EMAIL_RES",
    CODE_RES: "CODE_RES",
    FORGET_PASSWORD_CODE_SAGA: "FORGET_PASSWORD_CODE_SAGA",
    FORGET_PASSWORD_PHONE_SAGA: "FORGET_PASSWORD_PHONE_SAGA",
    FORGET_PASSWORD_EMAIL_SAGA: "FORGET_PASSWORD_EMAIL_SAGA",
    FORGET_PASSWORD_PHONE_CODE_SAGA: "FORGET_PASSWORD_PHONE_CODE_SAGA",
    FORGET_PASSWORD_EMAIL_CODE_SAGA: "FORGET_PASSWORD_EMAIL_CODE_SAGA",
    CLOSE_FORGET_ALERT: "CLOSE_FORGET_ALERT",
    SUBMITTED_FORGET: "SUBMITTED_FORGET",
    SUBMITTED_CODE_FORGET: "SUBMITTED_CODE_FORGET",
    FEEDBACK_SAGA: "FEEDBACK_SAGA", //FEEDBACK
    FEEDBACK_LOADING: "FEEDBACK_LOADING",
    FEEDBACK_SUBMITTED: "FEEDBACK_SUBMITTED",
    FEEDBACK: "FEEDBACK"
};
export const My_personal_details = {
    EDIT_MY_PHONE: "EDIT_MY_PHONE",  //EDIT_MY_PHONE
    SET_PHONE_INPUT_VALUES: "SET_PHONE_INPUT_VALUES",
    PHONE_SUBMITTED: "PHONE_SUBMITTED",
    CHACK_PHONE_SUBMITTED: "CHACK_PHONE_SUBMITTED",
    PHONE_LOADING: "PHONE_LOADING",
    EDIT_MY_PHONE_SAGA: "EDIT_MY_PHONE_SAGA",
    CHACK_MY_PHONE_SAGA: "CHACK_MY_PHONE_SAGA",
    SET_PHONE: "SET_PHONE",
    EDIT_MY_EMAIL: "EDIT_MY_EMAIL",  //EDIT_MY_EMAIL
    SET_EMAIL: "SET_EMAIL",
    SET_EMAIL_INPUT_VALUES: "SET_EMAIL_INPUT_VALUES",
    EMAIL_SUBMITTED: "EMAIL_SUBMITTED",
    CHACK_EMAIL_SUBMITTED: "CHACK_EMAIL_SUBMITTED",
    EMAIL_LOADING: "EMAIL_LOADING",
    EDIT_MY_EMAIL_SAGA: "EDIT_MY_EMAIL_SAGA",
    CHACK_MY_EMAIL_SAGA: "CHACK_MY_EMAIL_SAGA",
    EDIT_MY_PERSONAL_DETAILS: "EDIT_MY_PERSONAL_DETAILS", //EDIT_MY_PERSONAL_DETAILS
    SET_MY_INFO: "SET_MY_INFO",
    EDIT_MY_PERSONAL_DETAILS_SAGA: "EDIT_MY_PERSONAL_DETAILS_SAGA",
    GET_INPUT_VALUES: "GET_INPUT_VALUES",
    GET_MY_PERSONAL_DETAILS_SAGA: "GET_MY_PERSONAL_DETAILS_SAGA",
    GET_ME: "GET_ME",
    SUBMITTED: "SUBMITTED",
    SET_FILES_SAGA: "SET_FILES_SAGA",
    HELP: "HELP", //HELP
    HELP_SAGA: "HELP_SAGA",
    HELP_SUBMITTED: "HELP_SUBMITTED",
    HELP_INPUT: "HELP_INPUT",
    HELP_LOADING: "HELP_LOADING",
    SETTINGS: "SETTINGS", //SETTINGS
    SETTINGS_SAGA: "SETTINGS_SAGA",
    SETTINGS_SUBMITTED: "SETTINGS_SUBMITTED",
    SETTINGS_INPUT: "SETTINGS_INPUT",
    SETTINGS_LOADING: "SETTINGS_LOADING",
    SETTINGS_DATA: "SETTINGS_DATA",
    GET_SETTINGS_SAGA: "GET_SETTINGS_SAGA",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    SET_FOOTER: "SET_FOOTER",
    GET_FOOTER: "GET_FOOTER",
    CHANGE_PASSWORD:"CHANGE_PASSWORD",
    SET_PASSWORD:"SET_PASSWORD"
};
export const General = {
    GET_CITY_LOADING: "GET_CITY_LOADING",
    GET_CITY: "GET_CITY",
    GET_COUNTRY: "GET_COUNTRY",
    GET_COUNTRY_SAGA: "GET_COUNTRY_SAGA",
    GET_CITY_SAGA: "GET_CITY_SAGA",
    GET_GENDER: "GET_GENDER",
    GET_GENDER_SAGA: "GET_GENDER_SAGA",
    GET_LENGUAGE: "GET_LENGUAGE",
    GET_LENGUAGE_SAGA: "GET_LENGUAGE_SAGA",
    GET_MENU: "GET_MENU",
    SET_MENU: "SET_MENU"
};
export const Service = {
    GET_SERVICE: "GET_SERVICE",
    GET_CITY: "GET_CITY",
    GET_CITY_SAGA: "GET_CITY_SAGA",
    PLUS_SERVICE: "PLUS_SERVICE",
    GET_SERVICE_SAGA: "GET_SERVICE_SAGA",
    GET_MASTER_SAGA: "GET_MASTER_SAGA",
    GET_MASTER_LOADING: "GET_MASTER_LOADING",
    GET_MASTER: "GET_MASTER",
    GET_SELECTED_MASTER_SAGA: "GET_SELECTED_MASTER_SAGA",
    GET_SELECTED_MASTER: "GET_SELECTED_MASTER",
    SELECTED_MASTER_LOADING: "SELECTED_MASTER_LOADING",
    ADD_FAVORITE_MASTERS_SAGA: "ADD_FAVORITE_MASTERS_SAGA",
    ADD_FAVORITE_MASTERS: "ADD_FAVORITE_MASTERS",
    REMOVE_FAVORITE_MASTERS_SAGA: "REMOVE_FAVORITE_MASTERS_SAGA",
    GET_SERVICES_BY_ADDRESS: "GET_SERVICES_BY_ADDRESS"
};
export const Master = {
    ADD_GO_HOME_PRICE: "ADD_GO_HOME_PRICE",
    GET_MASTER_DATA: "GET_MASTER_DATA",
    GET_MASTER_DATA_SAGA: "GET_MASTER_DATA_SAGA",
    POST_FILES: "POST_FILES",
    POST_FILES_SAGA: "POST_FILES_SAGA",
    REVIEW_SAGA: "REVIEW_SAGA",
    REVIEW_LOADING: "REVIEW_LOADING",
    REVIEW: "REVIEW",
    MASTER_FILTER_SAGA: "MASTER_FILTER_SAGA",
    MASTER_FILTER: "MASTER_FILTER",
    MASTER_PARAMS: "MASTER_PARAMS",
    COMMENT: "COMMENT",
    ADD_DATA: 'ADD_DATA',
    GET_MASTER_SCHEDULE_LOADER: "GET_MASTER_SCHEDULE_LOADER",
    GET_MASTER_SCHEDULE: "GET_MASTER_SCHEDULE",
    GET_MASTER_SCHEDULE_SAGA: "GET_MASTER_SCHEDULE_SAGA",
    DELETE_MASTER_SCHEDULE: "DELETE_MASTER_SCHEDULE",
    CREATE_ORDER_SAGA: "CREATE_ORDER_SAGA",
    CREATE_ORDER: "CREATE_ORDER",
    SELECT_SCHEDULE: "SELECT_SCHEDULE",
    SELECT_CARD: "SELECT_CARD",
    CREATE_ORDER_LOADING: "CREATE_ORDER_LOADING",
    CLOSE_MODAL: "CLOSE_MODAL",
    OPEN_MODAL: "OPEN_MODAL",
    AVAILABLE_DAYS: "AVAILABLE_DAYS",
    AVAILABLE_DAYS_SAGA: "AVAILABLE_DAYS_SAGA",
    SERVICE_LOADER: "SERVICE_LOADER",
    SELECT_ADDRESS: "SELECT_ADDRESS",
    SEND_ORDER_CODE_SAGA: "SEND_ORDER_CODE_SAGA",
    GET_CLOSEST_CITIES:"GET_CLOSEST_CITIES",
    SET_CLOSEST_CITIES:"SET_CLOSEST_CITIES",
    GET_CLOSEST_MASTERS_POSTS:"GET_CLOSEST_MASTERS_POSTS",
    SET_CLOSEST_MASTERS_POSTS:"SET_CLOSEST_MASTERS_POSTS"
};
export const Card = {
    GET_CARD: "GET_CARD",
    GET_CARD_SAGA: "GET_CARD_SAGA",
    CREATE_ACCOUNT_SAGA: "CREATE_ACCOUNT_SAGA",
    CREATE_ACCOUNT: "CREATE_ACCOUNT",
    ADD_CARD_SAGA: "ADD_CARD_SAGA",
    ADD_CARD: "ADD_CARD",
    CARD_INPUTS_VALUE: "CARD_INPUTS_VALUE",
    SUBMITTED: "SUBMITTED",
    CREATE_CARD_LOADING: "CREATE_CARD_LOADING",
    GET_HISTORY: "GET_HISTORY",
    GET_HISTORY_SAGA: "GET_HISTORY_SAGA",
    HISTORY_LOADING: "HISTORY_LOADING",
    CARD_LOADING: "CARD_LOADING",
    DELETE_CARD_SAGA: "DELETE_CARD_SAGA"
};
export const Note = {
    MY_NOTE: "MY_NOTE",
    MY_NOTE_SAGA: "MY_NOTE_SAGA",
    CANCEL_NOTE: "CANCEL_NOTE",
    CANCEL_COMMENT_LOADING: "CANCEL_COMMENT_LOADING",
    COMMENT: "COMMENT",
    CANCEL_COMMENT_SAGA: "CANCEL_COMMENT_SAGA",
    ADD_REVIEW_SAGA: "ADD_REVIEW_SAGA",
    ADD_REVIEW: "ADD_REVIEW",
    ADD_REVIEW_LOADING: "ADD_REVIEW_LOADING",
    REVIEW: "REVIEW",
    SET_MASTER_ID: "SET_MASTER_ID",
    MY_NOTE_LOADING: "MY_NOTE_LOADING",
    CLOSE_ALERT: "CLOSE_ALERT",
    PAY_FOR_ORDER: "PAY_FOR_ORDER",
    PAY_FOR_ORDER_SAGA: "PAY_FOR_ORDER_SAGA",
    PAY_FOR_ORDER_LOADING: "PAY_FOR_ORDER_LOADING",
    DELETE_REVIEW: "DELETE_REVIEW",
    GET_CLIENT_ORDER_DAYS_SAGA: "GET_CLIENT_ORDER_DAYS_SAGA",
    SET_CLIENT_ORDER_DAYS: "SET_CLIENT_ORDER_DAYS",
    PAY_WITHOUT_ADD_CARD_SAGA : "PAY_WITHOUT_ADD_CARD_SAGA"

};
export const Chat = {
    CHAT_FILE: "CHAT_FILE",
    GET_ROOM_FOR_CLIENT: "GET_ROOM_FOR_CLIENT",
    GET_ROOM_FOR_CLIENT_SAGA: "GET_ROOM_FOR_CLIENT_SAGA",
};
export const Reviews = {
    GET_WITHOUT_REVIEWS: "GET_WITHOUT_REVIEWS",
    SET_WITHOUT_REVIEWS: "SET_WITHOUT_REVIEWS",
    GET_WITH_REVIEWS: "GET_WITH_REVIEWS",
    SET_WITH_REVIEWS: "SET_WITH_REVIEWS",
    LEAVE_REVIEW:"LEAVE_REVIEWS"
};